
import { defineComponent } from "vue";

import CargoRollCatalogue from "@/components/catalogues/CargoRoll/CargoRollCatalogue.vue";

export default defineComponent({
  components: { CargoRollCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
